// /?a=bschuss02%7C8j17ySrYNxExkuaqCYhlzDZmR

import React from "react"
import { BrowserRouter as Router, useLocation } from "react-router-dom"
import "./App.css"
import { Button, Box } from "@material-ui/core"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import firebase from "firebase"
import "firebase/firestore"

import ReactPDF, {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
} from "@react-pdf/renderer"
import { PDFViewer } from "@react-pdf/renderer"

const playStoreLogo = require("./google-play-badge.png")
const appStoreLogo = require("./Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg")

const testPdf = require("./testFile.pdf")

class Child extends React.Component {
	state = { sharedInfo: null }
	constructor({ string }) {
		super()
		//slices off part after a question mark to hide username inside qr code
		const urlString = window.location.href
		const questionMarkIndex = urlString.indexOf("?")
		if (questionMarkIndex !== -1 && urlString.indexOf("?a=") === -1) {
			window.location.href = urlString.substring(0, questionMarkIndex)
		}

		try {
			// const [username, documentKey] = string.split("|");
			this.state = { string, width: 0, height: 0 }
			this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
		} catch (error) {
			this.state = { error: true }
		}
	}

	downloadTxtFile = () => {
		const element = document.createElement("a")

		const { sharedInfo } = this.state

		let contactInfoString = ""
		sharedInfo.data.forEach((obj) => {
			contactInfoString += `${obj.platform}:\t${obj.info}\n`
		})

		let message =
			`${sharedInfo.firstname} ${sharedInfo.lastname}'s Bundal\n\n` +
			contactInfoString

		const file = new Blob([message], {
			type: "text/plain",
		})
		element.href = URL.createObjectURL(file)
		element.download = `${sharedInfo.firstname}${sharedInfo.lastname}Bundal.txt`
		document.body.appendChild(element) // Required for this to work in FireFox
		element.click()
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight })
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions)
	}

	async componentDidMount() {
		this.updateWindowDimensions()
		window.addEventListener("resize", this.updateWindowDimensions)
		if (!this.state.error) {
			try {
				const firebaseConfig = {
					apiKey: "AIzaSyC-77Z4_Yr7GK5orUdB0TQN-QQAV2rooFc",
					authDomain: "bundal-production.firebaseapp.com",
					projectId: "bundal-production",
					storageBucket: "bundal-production.appspot.com",
					messagingSenderId: "705188775668",
					appId: "1:705188775668:web:f02573c814fa12bcd380a1",
					measurementId: "G-0L286RGR9M",
				}

				if (!firebase.apps.length) {
					firebase.initializeApp(firebaseConfig)
				}

				const db = firebase.firestore()

				const urlString = window.location.href

				const slashIndex = urlString.substring(9).indexOf("/") + 9

				const urlAfterSlash = urlString.substring(slashIndex + 1)

				let documentKey = ""
				if (urlString.indexOf("?a=") === -1) {
					documentKey = urlAfterSlash
				} else {
					documentKey = this.state.string.split("|")[1]
				}

				// const documentKey = urlAfterSlash;
				const querySnapshot = await db
					.collection("anon_scans")
					.doc(documentKey)
					.get()
				const queryData = querySnapshot.data()

				if (!queryData) {
					this.setState({ error: true })
				}

				this.setState({ sharedInfo: queryData })
			} catch {
				this.setState({ error: true })
			}
		}

		// const { sharedInfo, error } = this.state
		// const showData = !error && sharedInfo

		// var vCard = require("vcards-js")
		// var myCard = vCard()
		// myCard.firstName = sharedInfo.firstname
		// myCard.lastName = sharedInfo.lastname
		// const filename = `${sharedInfo.firstname} ${sharedInfo.lastname}.vcf`

		// let notesString = ""
		// sharedInfo.data.forEach((contact) => {
		// 	switch (contact.quickAddFlag) {
		// 		case "phone": {
		// 			myCard.cellPhone = contact.info
		// 			break
		// 		}
		// 		default: {
		// 			break
		// 		}
		// 	}
		// 	const item = `${contact.platform}: ${contact.info}\n`
		// 	notesString += item
		// })
		// myCard.note = notesString

		// var newBlob = new Blob([
		// 	myCard.getFormattedString(),
		// 	{ type: "application/json" },
		// ])

		// var csvURL = window.URL.createObjectURL(newBlob)
		// var tempLink = document.createElement("a")
		// tempLink.href = csvURL
		// tempLink.setAttribute("download", filename)
		// // tempLink.click()
		// console.log("done")
	}

	render() {
		const { sharedInfo, error } = this.state
		const showData = !error && sharedInfo

		return (
			<Box>
				<div
					style={{
						display: "flex",
						backgroundColor: "#222328",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
						flex: 1,
						borderWidth: 10,
						borderColor: "white",
						borderRadius: 40,
					}}
				>
					{/* <div style={{ flex: 1 }}></div> */}
					<div
						style={{
							display: "flex",
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div
							style={{
								display: "flex",
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
							}}
						>
							<h1
								style={{
									width: "100%",
									textAlign: "center",
									color: "white",
								}}
							>
								Bundal
							</h1>
							{/* <img
								alt="Bundal logo"
								src={newlogo}
								style={{
									height: 80,
									width: 80,
									borderWidth: 1,
									borderColor: "#a0a0a0",
									borderRadius: 10,
									borderStyle: "solid",
									marginTop: -15,
								}}
							/> */}
						</div>
					</div>
				</div>

				{error && (
					<h3 style={{ width: "100%", textAlign: "center", color: "white" }}>
						{`Something went wrong. Try scanning again.`}
					</h3>
				)}
				<h3
					style={{
						width: "100%",
						textAlign: "center",
						color: "white",
					}}
				>
					{sharedInfo &&
						`Contact Info for ${sharedInfo.firstname} ${sharedInfo.lastname}`}
				</h3>
				<ToastContainer
					position="top-center"
					autoClose={2000}
					hideProgressBar={true}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover={false}
				/>

				{/* this is where the fun begins */}
				{showData && (
					<div
						style={{
							display: "flex",
							backgroundColor: "#222328",
							flexDirection: "column",
						}}
					>
						{sharedInfo.data.map((obj) => (
							<div
								style={{
									display: "flex",
									// backgroundColor: "#404040",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
									marginBottom: 20,
									padding: 10,
									// borderRadius: 40,
									// width: this.state.width < 800 ? "90%" : "60%",
									marginLeft: this.state.width < 800 ? "2.5%" : "20%",
									marginRight: this.state.width < 800 ? "2.5%" : "20%",
									borderColor: "#404040",
									borderStyle: "solid",
									borderWidth: 0,
									borderBottomWidth: 1,
								}}
							>
								<div
									style={{
										color: "white",
										borderTopLeftRadius: 40,
										borderBottomLeftRadius: 40,
										flex: 1,
										marginLeft: 10,
									}}
								>
									<p style={{ fontSize: 14 }}>{obj.platform}</p>
									<p style={{ fontSize: 14 }}>{obj.info}</p>
								</div>
								{/* <div
									style={{
										color: "white",
										flex: 1,
										alignItems: "center",
									}}
								>
									<p
										style={{
											textAlign: "center",
											marginRight: 20,
											marginLeft: 20,
											width:
												this.state.width > 300 ? this.state.width / 4 : 110,
											overflowWrap: "break-word",
										}}
									>
										{`${obj.info}`}
									</p>
								</div> */}
								<div
									style={{
										display: "flex",
										flex: 1,
										justifyContent: "flex-end",
									}}
								>
									<div
										style={{
											display: "flex",
											marginRight: 10,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										{obj.quickAddFlag && (
											<Button
												onClick={() => {
													if (obj.quickAddFlag === "instagram") {
														window.open(
															`https://instagram.com/${obj.info}`,
															"_blank",
														)
													} else if (obj.quickAddFlag === "snapchat") {
														window.open(
															`https://snapchat.com/add/${obj.info}`,
															"_blank",
														)
													} else if (obj.quickAddFlag === "email") {
														window.open(`mailto:${obj.info}`, "_blank")
													} else if (obj.quickAddFlag === "twitter") {
														window.open(
															`https://twitter.com/${obj.info}`,
															"_blank",
														)
													} else if (obj.quickAddFlag === "venmo") {
														window.open(
															`https://venmo.com/${obj.info}`,
															"_blank",
														)
													} else if (obj.quickAddFlag === "linkedin") {
														window.open(
															`https://linkedin.com/in/${obj.info}`,
															"_blank",
														)
													} else if (obj.quickAddFlag === "whatsapp") {
														window.open(`https://wa.me/1${obj.info}`, "_blank")
													} else if (obj.quickAddFlag === "telegram") {
														window.open(`https://t.me/${obj.info}`, "_blank")
													} else if (obj.quickAddFlag === "phone") {
														window.open(`sms://${obj.info}`, "_blank")
													}
												}}
												style={{
													backgroundColor: "#53b3cb",
													color: "#404040",
													borderRadius: 20,
													marginBottom: 10,
												}}
											>
												{"Open"}
											</Button>
										)}
										<CopyToClipboard
											text={obj.info}
											onCopy={() => {
												toast.dark(`Copied to clipboard: "${obj.info}"`, {})
											}}
										>
											<Button
												style={{
													backgroundColor: "#53b3cb",
													color: "#404040",
													borderRadius: 20,
													marginBottom: 10,
													marginLeft: 10,
													// fontSize: 15,
												}}
											>
												Copy
											</Button>
										</CopyToClipboard>
									</div>
								</div>
							</div>
						))}
					</div>
				)}

				{showData && (
					<div
						style={{
							display: "flex",
							flex: 1,
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
							}}
						>
							<a
								href="https://apps.apple.com/us/app/bundal/id1535565005"
								target="_blank"
							>
								<img src={appStoreLogo} style={{ width: 170, marginTop: 11 }} />
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=com.bundal.bundalandroid"
								target="_blank"
							>
								<img src={playStoreLogo} style={{ width: 200 }} />
							</a>
						</div>
						{/* <Button
							onClick={() => {
								console.log("clicked")
								var vCard = require("vcards-js")
								var myCard = vCard()
								//set properties
								myCard.firstName = "Eric"
								myCard.middleName = "T"
								myCard.lastName = "Nesser"
								myCard.organization = "ACME Corporation"
								myCard.photo.attachFromUrl(
									"https://avatars2.githubusercontent.com/u/5659221?v=3&s=460",
									"JPEG",
								)
								myCard.workPhone = "123-456-7890"
								myCard.birthday = new Date("01-01-1985")
								myCard.title = "Software Developer"
								myCard.url = "https://github.com/enesser"
								myCard.note = "Notes on Eric"

								// myCard.saveToFile("example.vcf")

								var newBlob = new Blob([
									myCard.getFormattedString(),
									{ type: "application/json" },
								])
								console.log(
									"🚀 ~ file: App.js ~ line 423 ~ Child ~ render ~ newBlob",
									newBlob,
								)

								var csvURL = window.URL.createObjectURL(newBlob)
								var tempLink = document.createElement("a")
								tempLink.href = csvURL
								tempLink.setAttribute("download", "filename.vcf")
								tempLink.click()
								console.log("done")
							}}
							style={{ backgroundColor: "white" }}
						>
							he
						</Button> */}
						{/* </a> */}
					</div>
				)}

				{/* <div
					style={{
						display: "flex",
						backgroundColor: "#222328",
						flexDirection: "column",
					}}
				>
					<div
						style={{
							display: "flex",
							backgroundColor: "#404040",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							marginBottom: 20,
							padding: 10,
							borderRadius: 40,
							width: this.state.width < 800 ? "60%" : "40%",
							marginLeft: this.state.width < 800 ? "20%" : "30%",
							marginRight: this.state.width < 800 ? "20%" : "30%",
						}}
					>
						<div
							style={{
								color: "white",
								borderTopLeftRadius: 40,
								borderBottomLeftRadius: 40,
								flex: 1,
								marginLeft: 10,
							}}
						>
							<p>asdf</p>
						</div>

						<div
							style={{
								color: "white",
								flex: 1,
								alignItems: "center",
								maxWidth: 200,
								minWidth: 150,
							}}
						>
							<p style={{ textAlign: "center" }}></p>
						</div>
						<div
							style={{
								display: "flex",
								flex: 1,
								justifyContent: "flex-end",
								marginTop: 10,
							}}
						>
							<Button
								onClick={this.downloadTxtFile}
								style={{
									backgroundColor: "#53b3cb",
									color: "#404040",
									borderRadius: 20,
									marginBottom: 10,
								}}
							>
								Download
							</Button>
						</div>
					</div>
				</div> */}

				{/* <div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<div
						style={{
							display: "flex",
							backgroundColor: "#404040",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							padding: 10,
							borderRadius: 40,
							// width: this.state.width < 800 ? "50%" : "50%",
							// marginLeft: this.state.width < 800 ? "30%" : "30%",
							// marginRight: this.state.width < 800 ? "30%" : "30%",
						}}
					>
						<Button onClick={this.downloadTxtFile}>Download</Button>
					</div>
				</div> */}
			</Box>
		)
	}
}

export default function QueryParamsExample() {
	return (
		<Router>
			<QueryParamsDemo />
		</Router>
	)
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
	return new URLSearchParams(useLocation().search)
}

function QueryParamsDemo() {
	let query = useQuery()

	return (
		<div>
			<Child string={query.get("a")} />
		</div>
	)
}

const MyDocument = () => (
	<Document>
		<Page size="A4" style={styles.page}>
			<View style={styles.section}>
				<Text>Section #1</Text>
			</View>
			<View style={styles.section}>
				<Text>Section #2</Text>
			</View>
		</Page>
	</Document>
)

const styles = StyleSheet.create({
	page: {
		flexDirection: "row",
		backgroundColor: "#E4E4E4",
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1,
	},
})
